fc.sourceNormalizers = [];
fc.sourceFetchers = [];

var ajaxDefaults = {
	dataType: 'json',
	cache: false,
};

fc.eventGUID = 1;
fc.eventCreationOrder = 1;

fc.currentFetchID = 0;
fc.currentMapFetchID = 0;
fc.currentUnscheduledFetchID = 0;

fc.eventReportingCallbacks = {};
fc.mapEnabled = false;
fc.unscheduledEnabled = false;

function EventManager(options) {
	// assumed to be a calendar
	var t = this;

	// exports
	t.getEventSources = getEventSources;
	t.isFetchNeeded = isFetchNeeded;
	t.fetchEvents = fetchEvents;
	t.enableMap = enableMap;
	t.disableMap = disableMap;
	t.fetchMapEvents = fetchMapEvents;
	t.enableUnscheduled = enableUnscheduled;
	t.disableUnscheduled = disableUnscheduled;
	t.fetchUnscheduledEvents = fetchUnscheduledEvents;
	t.setReportingCallback = setReportingCallback;
	t.getReportingCallback = getReportingCallback;
	t.refetchEventSource = refetchEventSource;
	t.addEventSource = addEventSource;
	t.removeEventSource = removeEventSource;
	t.renderUnscheduledEvents = renderUnscheduledEvents;
	t.unscheduledToEvent = unscheduledToEvent;
	t.eventToUnscheduled = eventToUnscheduled;
	t.updateEvent = updateEvent;
	t.buildEventDates = buildEventDates;
	t.renderEvent = renderEvent;
	t.renderEvents = renderEvents;
	t.removeEvents = removeEvents;
	t.clientEvents = clientEvents;
	t.mapClientEvents = mapClientEvents;
	t.unscheduledClientEvents = unscheduledClientEvents;
	t.mutateEvent = mutateEvent;

	t.getPendingSourceCount = function () {
		return pendingSourceCnt;
	};

	t.getPendingMapSourceCount = function () {
		return pendingMapSourceCnt;
	};

	t.getPendingUnscheduledSourceCount = function () {
		return pendingUnscheduledSourceCnt;
	};

	// imports
	var trigger = t.trigger;
	var getView = t.getView;
	var reportEvents = t.reportEvents;
	var reportMapEvents = t.reportMapEvents;
	var reportUnscheduledEvents = t.reportUnscheduledEvents;
	var getEventEnd = t.getEventEnd;

	// locals
	var stickySource = {events: []};
	var sources = [stickySource];
	var rangeStart, rangeEnd;
	var pendingSourceCnt = 0;
	var pendingMapSourceCnt = 0;
	var pendingUnscheduledSourceCnt = 0;
	var loadingLevel = 0;
	var unscheduledLoadingLevel = 0;
	var mapLoadingLevel = 0;
	var cache = [];
	var mapCache = [];
	var unscheduledCache = [];

	//Used for background fetching of events
	var backgroundCacheStart, backgroundCacheEnd;
	var backgroundCache = [];
	var backgroundUnscheduledCache = [];

	var _sources = options.eventSources || [];

	// TODO: don't mutate eventSources (see issue 954 and automated tests for constructor.js)

	if (options.events) {
		_sources.push(options.events);
	}

	for (var i = 0; i < _sources.length; i++) {
		_addEventSource(_sources[i]);
	}

	function setReportingCallback(id, callback) {
		fc.eventReportingCallbacks[id] = callback;
	}

	function getReportingCallback(id) {
		return fc.eventReportingCallbacks[id];
	}

	/* Fetching map
	-----------------------------------------------------------------------------*/
	function enableMap(callbacks) {
		fc.mapEnabled = true;
		setReportingCallback('mapEvents', callbacks.updateMap);
		setReportingCallback('mapLoading', callbacks.mapLoading);
		setReportingCallback(
			'reportGeocodeChanged',
			callbacks.reportGeocodeChanged
		);
		setReportingCallback(
			'reportSchedulesLoaded',
			callbacks.reportSchedulesLoaded
		);
		// Fetch map events to get non async sources
		// fetchMapEvents(false, null);
	}

	function disableMap() {
		fc.unscheduledEnabled = false;
		setReportingCallback('map', null);
		setReportingCallback('mapLoading', null);
		setReportingCallback('reportSchedulesLoaded', null);
		mapCache = [];
	}

	function fetchMapEvents(bounds, callback) {
		if (!fc.mapEnabled) {
			return;
		}

		var loadingCallback = getReportingCallback('mapLoading');

		// Call loading callback
		if (loadingCallback) {
			loadingCallback(true);
		}

		mapCache = [];
		var fetchID = ++fc.currentMapFetchID;
		var len = sources.length;
		mapLoadingLevel = 0; // If we have a new fetchID we should reset the loading level so we properly report loading status
		pendingMapSourceCnt = len;

		for (var i = 0; i < len; i++) {
			fetchMapSource(sources[i], bounds, fetchID, callback);
		}
	}

	function fetchMapSource(source, bounds, fetchID, callback) {
		var loadingCallback = getReportingCallback('mapLoading');
		if (pendingMapSourceCnt) {
			if (loadingCallback) {
				loadingCallback(true);
			}
		}
		_fetchMapSource(source, bounds, fetchID, function (events) {
			if (fetchID == fc.currentMapFetchID) {
				if (events) {
					for (var i = 0; i < events.length; i++) {
						var event = buildEvent(events[i], source);
						if (event) {
							mapCache.push(event);
						}
					}
				}

				pendingMapSourceCnt--;
				if (!pendingMapSourceCnt) {
					if (loadingCallback) {
						loadingCallback(false);
					}
					// reportMapEvents(mapCache); // we don't report this way becauase we call map events async after map is moved
					callback(mapCache);
				}
			}
		});
	}

	function _fetchMapSource(source, bounds, fetchID, callback) {
		var events = source.mapEvents;
		if (events) {
			if ($.isFunction(events)) {
				pushMapLoading(fetchID, source);
				events.call(
					this,
					bounds,
					function (events) {
						if (fetchID == fc.currentMapFetchID) {
							preventLoad = popMapLoading(fetchID, source);
							if (preventLoad) {
								events = [];
							}
							callback(events);
						}
					},
					fetchID
				);
			} else if ($.isArray(events)) {
				callback(events);
			} else {
				callback();
			}
		} else {
			callback();
		}
	}

	/* Fetching Unscheduled
	-----------------------------------------------------------------------------*/
	function enableUnscheduled(callbacks) {
		fc.unscheduledEnabled = true;
		setReportingCallback('unscheduled', callbacks.updateUnscheduled);
		setReportingCallback(
			'unscheduledLoading',
			callbacks.unscheduledLoading
		);
		setReportingCallback(
			'reportSchedulesLoaded',
			callbacks.reportSchedulesLoaded
		);
		setReportingCallback('unscheduledDrag', callbacks.onExternalDrag);
		setReportingCallback('unscheduledDrop', callbacks.onExternalDrop);
		// Fetch unscheduled events to get non async sources
		fetchUnscheduledEvents(false, null);
	}

	function disableUnscheduled() {
		fc.unscheduledEnabled = false;
		setReportingCallback('unscheduled', null);
		setReportingCallback('unscheduledLoading', null);
		setReportingCallback('reportSchedulesLoaded', null);
		setReportingCallback('unscheduledDrag', null);
		setReportingCallback('unscheduledDrop', null);
		unscheduledCache = [];
	}

	function fetchUnscheduledEvents(backgroundFetch, callback) {
		if (!fc.unscheduledEnabled && !backgroundFetch) {
			return;
		}

		var loadingCallback = getReportingCallback('unscheduledLoading');

		// Call loading callback
		loadingCallback(true);

		unscheduledCache = [];
		var fetchID = ++fc.currentUnscheduledFetchID;
		var len = sources.length;
		unscheduledLoadingLevel = 0; // If we have a new fetchID we should reset the loading level so we properly report loading status
		pendingUnscheduledSourceCnt = len;

		if (backgroundFetch) {
			backgroundUnscheduledCache = [];
		}

		for (var i = 0; i < len; i++) {
			fetchUnscheduledSource(
				sources[i],
				fetchID,
				backgroundFetch,
				callback
			);
		}
	}

	function fetchUnscheduledSource(
		source,
		fetchID,
		backgroundFetch,
		callback
	) {
		var loadingCallback = getReportingCallback('unscheduledLoading');
		if (pendingUnscheduledSourceCnt) {
			loadingCallback(true);
		}
		_fetchUnscheduledSource(source, fetchID, function (events) {
			if (fetchID == fc.currentUnscheduledFetchID) {
				if (events) {
					for (var i = 0; i < events.length; i++) {
						var event = buildEvent(events[i], source);
						if (event) {
							if (backgroundFetch) {
								backgroundUnscheduledCache.push(event);
							} else {
								unscheduledCache.push(event);
							}
						}
					}
				}

				pendingUnscheduledSourceCnt--;
				if (!pendingUnscheduledSourceCnt) {
					loadingCallback(false);
					if (backgroundFetch) {
						if (callback) {
							callback(backgroundUnscheduledCache);
						}
					} else {
						reportUnscheduledEvents(unscheduledCache);
					}
				}
			}
		});
	}

	function _fetchUnscheduledSource(source, fetchID, callback) {
		var events = source.unscheduled;
		if (events) {
			if ($.isFunction(events)) {
				pushUnscheduledLoading(fetchID, source);
				events.call(
					this,
					function (events) {
						if (fetchID == fc.currentUnscheduledFetchID) {
							preventLoad = popUnscheduledLoading(
								fetchID,
								source
							);
							if (preventLoad) {
								events = [];
							}
							callback(events);
						}
					},
					fetchID
				);
			} else if ($.isArray(events)) {
				callback(events);
			} else {
				callback();
			}
		} else {
			callback();
		}
	}

	/* Fetching Events
	-----------------------------------------------------------------------------*/
	function getEventSources() {
		return sources;
	}

	function isFetchNeeded(start, end) {
		var view = getView();

		return (
			!rangeStart || // nothing has been fetched yet?
			// or, a part of the new range is outside of the old range? (after normalizing)
			((view.name === 'basicHorizon' ||
				view.name === 'basicResourceHor') &&
				!start
					.clone()
					.stripZone()
					.isSame(rangeStart.clone().stripZone())) ||
			start.clone().stripZone() < rangeStart.clone().stripZone() ||
			end.clone().stripZone() > rangeEnd.clone().stripZone()
		);
	}

	function fetchEvents(start, end, backgroundFetch, callback) {
		rangeStart = start;
		rangeEnd = end;
		cache = [];
		var fetchID = ++fc.currentFetchID;
		var len = sources.length;
		loadingLevel = 0; // If we have a new fetchID we should reset the loading level so we properly report loading status
		pendingSourceCnt = len;

		if (backgroundFetch) {
			backgroundCache = [];
			backgroundCacheStart = start.clone();
			backgroundCacheEnd = end.clone();
		}

		for (var i = 0; i < len; i++) {
			fetchEventSource(sources[i], fetchID, backgroundFetch, callback);
		}
	}

	function fetchEventSource(source, fetchID, backgroundFetch, callback) {
		_fetchEventSource(source, fetchID, function (events) {
			if (fetchID == fc.currentFetchID) {
				if (events) {
					for (var i = 0; i < events.length; i++) {
						var event = buildEvent(events[i], source);
						if (event) {
							if (backgroundFetch) {
								backgroundCache.push(event);
							} else {
								cache.push(event);
							}
						}
					}
				}

				pendingSourceCnt--;
				if (!pendingSourceCnt) {
					if (backgroundFetch) {
						if (callback) {
							callback(backgroundCache);
						}
					} else {
						reportEvents(cache);
					}
				}
			}
		});
	}

	function _fetchEventSource(source, fetchID, callback) {
		var i;
		var fetchers = fc.sourceFetchers;
		var res;
		var preventLoad;
		for (i = 0; i < fetchers.length; i++) {
			res = fetchers[i].call(
				t, // this, the Calendar object
				source,
				rangeStart.clone(),
				rangeEnd.clone(),
				options.timezone,
				callback,
				fetchID
			);

			if (res === true) {
				// the fetcher is in charge. made its own async request
				return;
			} else if (typeof res == 'object') {
				// the fetcher returned a new source. process it
				_fetchEventSource(res, fetchID, callback);
				return;
			}
		}

		var events = source.events;
		if (events) {
			if ($.isFunction(events)) {
				pushLoading(fetchID, source);
				events.call(
					t, // this, the Calendar object
					rangeStart.clone(),
					rangeEnd.clone(),
					options.timezone,
					function (events) {
						if (fetchID == fc.currentFetchID) {
							preventLoad = popLoading(fetchID, source);
							if (preventLoad) {
								events = [];
							}
							callback(events);
						}
					},
					fetchID
				);
			} else if ($.isArray(events)) {
				callback(events);
			} else {
				callback();
			}
		} else {
			var url = source.url;
			if (url) {
				var success = source.success;
				var error = source.error;
				var complete = source.complete;

				// retrieve any outbound GET/POST $.ajax data from the options
				var customData;
				if ($.isFunction(source.data)) {
					// supplied as a function that returns a key/value object
					customData = source.data();
				} else {
					// supplied as a straight key/value object
					customData = source.data;
				}

				// use a copy of the custom data so we can modify the parameters
				// and not affect the passed-in object.
				var data = $.extend({}, customData || {});

				var startParam = firstDefined(
					source.startParam,
					options.startParam
				);
				var endParam = firstDefined(source.endParam, options.endParam);
				var timezoneParam = firstDefined(
					source.timezoneParam,
					options.timezoneParam
				);

				if (startParam) {
					data[startParam] = rangeStart.format();
				}
				if (endParam) {
					data[endParam] = rangeEnd.format();
				}
				if (options.timezone && options.timezone != 'local') {
					data[timezoneParam] = options.timezone;
				}

				pushLoading(fetchID, source);
				$.ajax(
					$.extend({}, ajaxDefaults, source, {
						data: data,
						success: function (events) {
							events = events || [];
							var res = applyAll(success, this, arguments);
							if ($.isArray(res)) {
								events = res;
							}
							callback(events);
						},
						error: function () {
							applyAll(error, this, arguments);
							callback();
						},
						complete: function () {
							preventLoad = popLoading(fetchID, source);
							applyAll(complete, this, arguments);
						},
					})
				);
			} else {
				callback();
			}
		}
	}

	/* Sources
	-----------------------------------------------------------------------------*/

	function refetchEventSource(source, scheduleID) {
		const wasUnscheduledEnabled = fc.unscheduledEnabled;
		fc.unscheduledEnabled = false;
		removeEventSource(source, scheduleID);
		addEventSource(source);
		fc.unscheduledEnabled = wasUnscheduledEnabled;
	}

	function addEventSource(source) {
		source = _addEventSource(source);
		if (source) {
			pendingSourceCnt++;
			if (rangeStart) {
				fetchEventSource(source, fc.currentFetchID); // will eventually call reportEvents
			}
			// Fetch map
			if (fc.mapEnabled) {
				pendingUnscheduledSourceCnt++;
				// fetchMapSource(source, fc.currentMapFetchID);
			}
			// Fetch unscheduled
			if (fc.unscheduledEnabled) {
				pendingUnscheduledSourceCnt++;
				fetchUnscheduledSource(source, fc.currentUnscheduledFetchID);
			}
		}
	}

	function _addEventSource(source) {
		if ($.isFunction(source) || $.isArray(source)) {
			source = {events: source};
		} else if (typeof source == 'string') {
			source = {url: source};
		}
		if (typeof source == 'object') {
			normalizeSource(source);
			sources.push(source);
			return source;
		}
	}

	function removeEventSource(source, scheduleID) {
		source.disableSchedule(scheduleID);
		sources = $.grep(sources, function (src) {
			return !isSourcesEqual(src, source);
		});
		// remove all client events from that source
		cache = $.grep(cache, function (e) {
			return !isSourcesEqual(e.source, source); // todo: get source from schedule so we don't have to remove orphans first
		});
		reportEvents(cache);

		if (fc.unscheduledEnabled) {
			// remove all unscheduled events from that source
			unscheduledCache = $.grep(unscheduledCache, function (e) {
				return !isSourcesEqual(e.schedule?.source, source); // get source from schedule since it always exists
			});
			reportUnscheduledEvents(unscheduledCache);
		}
		if (fc.mapEnabled) {
			// remove all unscheduled events from that source
			mapCache = $.grep(mapCache, function (e) {
				return !isSourcesEqual(e.schedule?.source, source); // get source from schedule since it always exists
			});
			reportMapEvents(mapCache);
		}
	}

	function renderUnscheduledEvents() {
		reportUnscheduledEvents(unscheduledCache);
	}

	/* Manipulation
	-----------------------------------------------------------------------------*/

	function unscheduledToEvent(
		event,
		newStart,
		newEnd,
		breakoutData,
		callback
	) {
		var undoMutation;
		var unscheduledPosition;

		for (var i = 0; i < unscheduledCache.length; i++) {
			if (unscheduledCache[i]._id === event._id) {
				unscheduledPosition = i;
				unscheduledCache.splice(i, 1); // remove item from unscheduledCache
				break;
			}
		}
		reportUnscheduledEvents(unscheduledCache);

		cache.push(event);

		event.unscheduled = false;
		event._unscheduled = true;

		// If we are setting an unsheduled
		if (newStart) {
			event.start = newStart.clone();
		} else if (!event.start) {
			event.start = moment()
				.hour(moment().hour())
				.minute(0)
				.millisecond(0);
		}

		undoMutation = mutateEvent(
			event,
			newStart,
			newEnd,
			breakoutData?.field === 'schedule' ? null : breakoutData,
			null
		);

		propagateMiscProperties(event);
		reportEvents(cache);

		// Reset unscheduled backup
		event._unscheduled = event.unscheduled;

		if (callback) {
			callback(event, unscheduledPosition, undoMutation);
		}
	}

	function eventToUnscheduled(event, unscheduledPosition, callback) {
		var undoMutation;
		cache = $.grep(cache, function (e) {
			return e._id !== event._id;
		});

		reportEvents(cache);

		event.unscheduled = true;
		event._unscheduled = false;

		undoMutation = mutateEvent(event);

		unscheduledCache.splice(unscheduledPosition || 0, 0, event);
		reportUnscheduledEvents(unscheduledCache);

		// Reset unscheduled backup
		event._unscheduled = event.unscheduled;

		if (callback) {
			callback(event, undoMutation);
		}
	}

	function updateEvent(event) {
		if (event.start) {
			event.start = t.moment(event.start);
		}
		if (event.end) {
			event.end = t.moment(event.end);
		}

		if (
			getReportingCallback('reportGeocodeChanged') &&
			!event.schedule.queryOnGeocode &&
			(event._geocode?.lat !== event.geocode?.lat ||
				event._geocode?.lng !== event.geocode?.lng)
		) {
			// Run callback function to report that a geocode has changed and we want to respond to that change
			getReportingCallback('reportGeocodeChanged')();
		}

		if (
			typeof event._unscheduled !== 'undefined' &&
			event.unscheduled !== event._unscheduled
		) {
			if (event.unscheduled) {
				// Move from event to unscheduled
				eventToUnscheduled(event, null, null);
			} else {
				// Move from unscheduled to event
				unscheduledToEvent(event, null, null, null, null);
			}
			return;
		}

		mutateEvent(event);
		propagateMiscProperties(event);
		if (event.unscheduled) {
			reportUnscheduledEvents(unscheduledCache);
		} else {
			reportEvents(cache); // reports event modifications (so we can redraw)
		}
	}

	var miscCopyableProps = [
		'title',
		'url',
		'allDay',
		'className',
		'editable',
		'color',
		'backgroundColor',
		'borderColor',
		'textColor',
	];

	function propagateMiscProperties(event) {
		var i;
		var cachedEvent;
		var j;
		var prop;

		for (i = 0; i < cache.length; i++) {
			cachedEvent = cache[i];
			if (cachedEvent._id == event._id && cachedEvent !== event) {
				for (j = 0; j < miscCopyableProps.length; j++) {
					prop = miscCopyableProps[j];
					if (event[prop] !== undefined) {
						cachedEvent[prop] = event[prop];
					}
				}
			}
		}
	}

	function renderEvent(eventData, stick) {
		const event = buildEvent(eventData);
		if (event) {
			if (event.unscheduled) {
				unscheduledCache.push(event);
				reportUnscheduledEvents(unscheduledCache);
			} else {
				if (!event.source) {
					if (stick) {
						stickySource.events.push(event);
						event.source = stickySource;
					}
					cache.push(event);
				}
				reportEvents(cache);
			}
		}
	}

	function renderEvents(eventArray, stick) {
		let hasUnscheduled;
		let hasEvent;
		for (var i = 0; i < eventArray.length; i++) {
			var eventData = eventArray[i];
			var event = buildEvent(eventData);
			if (event) {
				if (event.unscheduled) {
					hasUnscheduled = true;
					unscheduledCache.push(event);
				} else {
					if (!event.source) {
						if (stick) {
							stickySource.events.push(event);
							event.source = stickySource;
						}
						hasEvent = true;
						cache.push(event);
					}
				}
			}
		}

		if (hasEvent) {
			reportEvents(cache);
		}

		if (hasUnscheduled) {
			reportUnscheduledEvents(unscheduledCache);
		}
	}

	function removeEvents(filters) {
		// Filters can be an object that includes an _id property
		// A string that represents an id
		// A function that provides a match criteria
		// An array that includes any of the above

		const unscheduledCount = unscheduledCache.length;
		const eventCount = cache.length;

		let i;
		if (!filters) {
			// remove all
			cache = [];
			// clear all array sources
			for (i = 0; i < sources.length; i++) {
				if ($.isArray(sources[i].events)) {
					sources[i].events = [];
				}
			}
		} else {
			if (Array.isArray(filters)) {
				for (
					var filterIterator = 0;
					filterIterator < filters.length;
					filterIterator++
				) {
					applyRemovedEvents(filters[filterIterator]);
				}
			} else {
				applyRemovedEvents(filters);
			}
		}

		if (eventCount !== cache.length) reportEvents(cache);

		if (
			fc.unscheduledEnabled &&
			unscheduledCount !== unscheduledCache.length
		) {
			reportUnscheduledEvents(unscheduledCache);
		}
	}

	function applyRemovedEvents(filter) {
		if (!$.isFunction(filter)) {
			// an event ID
			const id = filter?._id ? filter._id : filter + '';
			filter = function (e) {
				return e._id == id;
			};
		}

		cache = $.grep(cache, filter, true);

		if (fc.unscheduledEnabled) {
			unscheduledCache = $.grep(unscheduledCache, filter, true);
		}
		// remove events from array sources
		for (let i = 0; i < sources.length; i++) {
			if ($.isArray(sources[i].events)) {
				sources[i].events = $.grep(sources[i].events, filter, true);
			}
		}
	}

	function mapClientEvents(filter) {
		if ($.isFunction(filter)) {
			return $.grep(mapCache, filter);
		} else if (filter) {
			// an event ID
			filter += '';
			return $.grep(mapCache, function (e) {
				return e._id == filter;
			});
		}
		return mapCache; // else, return all
	}

	function unscheduledClientEvents(filter) {
		if ($.isFunction(filter)) {
			return $.grep(unscheduledCache, filter);
		} else if (filter) {
			// an event ID
			filter += '';
			return $.grep(unscheduledCache, function (e) {
				return e._id == filter;
			});
		}
		return unscheduledCache; // else, return all
	}

	function clientEvents(filter) {
		if ($.isFunction(filter)) {
			return $.grep(cache, filter);
		} else if (filter) {
			// an event ID
			filter += '';
			return $.grep(cache, function (e) {
				return e._id == filter;
			});
		}
		return cache; // else, return all
	}

	/* Loading State
	-----------------------------------------------------------------------------*/
	function cancelLoading(fetchID) {
		pendingSourceCnt = 0;
		loadingLevel = 0;
	}

	function cancelMapLoading(fetchID) {
		pendingMapSourceCnt = 0;
		mapLoadingLevel = 0;
	}

	function cancelUnscheduledLoading(fetchID) {
		pendingUnscheduledSourceCnt = 0;
		unscheduledLoadingLevel = 0;
	}

	function pushMapLoading(fetchID, source) {
		trigger(
			'fetchMapStart',
			null,
			{fetchID: fetchID, source: source},
			getView()
		);
		if (!unscheduledLoadingLevel++) {
			trigger(
				'loadingMap',
				null,
				{
					isLoading: true,
					fetchID: fetchID,
					source: source,
					cancelLoadingCallback: cancelMapLoading,
				},
				getView()
			);
		}
	}

	function popMapLoading(fetchID, source) {
		var preventLoad = trigger(
			'fetchMapEnd',
			null,
			{fetchID: fetchID, source: source},
			getView()
		);
		if (!preventLoad && !--unscheduledLoadingLevel) {
			trigger(
				'loadingMap',
				null,
				{
					isLoading: false,
					fetchID: fetchID,
					source: source,
					cancelLoadingCallback: cancelMapLoading,
				},
				getView()
			);
		}
		return preventLoad;
	}

	function pushUnscheduledLoading(fetchID, source) {
		trigger(
			'fetchUnscheduledStart',
			null,
			{fetchID: fetchID, source: source},
			getView()
		);
		if (!unscheduledLoadingLevel++) {
			trigger(
				'loadingUnscheduled',
				null,
				{
					isLoading: true,
					fetchID: fetchID,
					source: source,
					cancelLoadingCallback: cancelUnscheduledLoading,
				},
				getView()
			);
		}
	}

	function popUnscheduledLoading(fetchID, source) {
		var preventLoad = trigger(
			'fetchUnscheduledEnd',
			null,
			{fetchID: fetchID, source: source},
			getView()
		);
		if (!preventLoad && !--unscheduledLoadingLevel) {
			trigger(
				'loadingUnscheduled',
				null,
				{
					isLoading: false,
					fetchID: fetchID,
					source: source,
					cancelLoadingCallback: cancelUnscheduledLoading,
				},
				getView()
			);
		}
		return preventLoad;
	}

	function pushLoading(fetchID, source) {
		trigger(
			'fetchStart',
			null,
			{fetchID: fetchID, source: source},
			getView()
		);
		if (!loadingLevel++) {
			trigger(
				'loading',
				null,
				{
					isLoading: true,
					fetchID: fetchID,
					source: source,
					cancelLoadingCallback: cancelLoading,
				},
				getView()
			);
		}
	}

	function popLoading(fetchID, source) {
		var preventLoad = trigger(
			'fetchEnd',
			null,
			{fetchID: fetchID, source: source},
			getView()
		);
		if (!preventLoad && !--loadingLevel) {
			trigger(
				'loading',
				null,
				{
					isLoading: false,
					fetchID: fetchID,
					source: source,
					cancelLoadingCallback: cancelLoading,
				},
				getView()
			);
		}
		return preventLoad;
	}

	/* Event Normalization
	-----------------------------------------------------------------------------*/

	function buildEvent(data, source) {
		var out = {};
		// source may be undefined!
		if (options.eventDataTransform) {
			data = options.eventDataTransform(data);
		}
		if (source && source.eventDataTransform) {
			data = source.eventDataTransform(data);
		}

		// Copy all properties over to the resulting object.
		// The special-case properties will be copied over afterwards.
		$.extend(out, data);

		if (source) {
			out.source = source;
		}

		out._id =
			data._id ||
			(data.id === undefined ? '_fc' + fc.eventGUID++ : data.id + '');

		out._order = fc.eventCreationOrder++;

		if (data.className) {
			if (typeof data.className == 'string') {
				out.className = data.className.split(/\s+/);
			} else {
				// assumed to be an array
				out.className = data.className;
			}
		} else {
			out.className = [];
		}

		buildEventDates(data, out, source);

		backupEventDates(out);

		return out;
	}

	function buildEventDates(data, out, source) {
		var start;
		var end;
		var allDay;
		var allDayDefault;

		// Return if there is no valid start date and this is not unscheduled
		if (!data.start && !data.date && !data.unscheduled) {
			return;
		}

		start = data.start || data.date || null;
		end = data.end || null;

		start = end && !start ? t.moment(end) : start ? t.moment(start) : null;
		if (start && !start.isValid()) {
			return;
		}

		end = start && !end ? t.moment(start) : end ? t.moment(end) : null;
		if (end && !end.isValid()) {
			return;
		}

		allDay = data.allDay;
		if (allDay === undefined) {
			allDayDefault = firstDefined(
				source ? source.allDayDefault : undefined,
				options.allDayDefault
			);
			if (allDayDefault !== undefined) {
				// use the default
				allDay = allDayDefault;
			} else {
				// all dates need to have ambig time for the event to be considered allDay
				allDay =
					(!start || !start.hasTime()) && (!end || !end.hasTime());
			}
		}

		// normalize the date based on allDay
		if (allDay) {
			// neither date should have a time
			if (start && start.hasTime()) {
				start.stripTime();
			}
			if (end && end.hasTime()) {
				end.stripTime();
			}
		} else {
			//Strip the timezone to normalize events then rezone based on normalized data
			if (start) {
				start = start.clone().stripZone();
			}
			if (end) {
				end = end.clone().stripZone();
			}

			// force a time/zone up the dates
			if (start && !start.hasTime()) {
				start = t.rezoneDate(start);
			}
			if (end && !end.hasTime()) {
				end = t.rezoneDate(end);
			}
		}

		out.allDay = allDay;
		out.start = start;
		out.end = end;

		if (!data.unscheduled && options.forceEventDuration && !out.end) {
			out.end = getEventEnd(out);
		}
		if (allDay) {
			//Customized for SeedCode. We want to display all day events as inclusive rather than exclusive end day
			//this logic is also being used in shares-services.js and should be consolidated.
			if (out.end) {
				out.end.add(1, 'day');
			}
		}
	}

	/* Event Modification Math
	-----------------------------------------------------------------------------------------*/

	// Modify the date(s) of an event and make this change propagate to all other events with
	// the same ID (related repeating events).
	//
	// If `newStart`/`newEnd` are not specified, the "new" dates are assumed to be `event.start` and `event.end`.
	// The "old" dates to be compare against are always `event._start` and `event._end` (set by EventManager).
	//
	// Returns a function that can be called to undo all the operations.
	//
	function mutateEvent(event, newStart, newEnd, newBreakoutData, isClone) {
		var oldAllDay = event._allDay;
		var oldStart = event._start;
		var oldEnd = event._end;
		var clearEnd = false;
		var newAllDay;
		var dateDelta;
		var durationDelta;

		// NOTE: throughout this function, the initial values of `newStart` and `newEnd` are
		// preserved. These values may be undefined.

		// if no new dates were passed in, compare against the event's existing dates
		if (!newStart && !newEnd && event.start && event.end) {
			newStart = event.start;
			newEnd = event.end;
		}

		if (newStart && !oldStart) {
			oldStart = newStart.clone();
		}

		if (newEnd && !oldEnd) {
			oldEnd = newEnd.clone();
		}

		// detect new allDay
		if (event.allDay != oldAllDay) {
			// if value has changed, use it
			newAllDay = event.allDay;
		} else if (newStart || newEnd) {
			// otherwise, see if any of the new dates are allDay
			newAllDay = !(newStart || newEnd).hasTime();
		}

		// normalize the new dates based on allDay
		if (newAllDay) {
			if (newStart) {
				newStart = newStart.clone().stripTime();
			}
			if (newEnd) {
				newEnd = newEnd.clone().stripTime();
			}
		}

		// compute dateDelta
		if (newStart) {
			if (newAllDay) {
				dateDelta = dayishDiff(newStart, oldStart.clone().stripTime()); // treat oldStart as allDay
			} else {
				dateDelta = dayishDiff(newStart, oldStart);
			}
		}

		if (newAllDay != oldAllDay) {
			clearEnd = true;
		} else if (newEnd) {
			durationDelta = dayishDiff(
				// new duration
				newEnd || t.getDefaultEventEnd(newAllDay, newStart || oldStart),
				newStart || oldStart
			).subtract(
				dayishDiff(
					// subtract old duration
					oldEnd || t.getDefaultEventEnd(oldAllDay, oldStart),
					oldStart
				)
			);
		}

		if (isClone) {
			return getMutateChanges(
				event,
				clearEnd,
				newAllDay,
				dateDelta,
				durationDelta,
				newBreakoutData
			);
		} else {
			return mutateEvents(
				event.unscheduled
					? unscheduledClientEvents(event._id)
					: clientEvents(event._id), // get events with this ID
				clearEnd,
				newAllDay,
				dateDelta,
				durationDelta,
				newBreakoutData
			);
		}
	}

	function getMutateChanges(
		event,
		clearEnd,
		forceAllDay,
		dateDelta,
		durationDelta,
		newBreakoutData,
		undoFunctions
	) {
		var allowNullDates = event.unscheduled && !durationDelta;
		var isAmbigTimezone = t.getIsAmbigTimezone();
		var breakoutItem = newBreakoutData ? newBreakoutData.value : null;
		var breakoutField = newBreakoutData
			? newBreakoutData.field
			: 'resource';
		var oldAllDay = event._allDay;
		var oldStart = event._start;
		var oldEnd = event._end;
		var newAllDay = forceAllDay != null ? forceAllDay : oldAllDay;
		var newStart = oldStart && !allowNullDates ? oldStart.clone() : null;
		var newEnd =
			!clearEnd && oldEnd && !allowNullDates ? oldEnd.clone() : null;
		var oldBreakoutItem =
			event[breakoutField] && Array.isArray(event[breakoutField])
				? event[breakoutField].slice(0)
				: event[breakoutField];
		var oldIsDefaultResource =
			breakoutField === 'resource' ? event.isDefaultResource : false;
		var oldUnscheduled = event._unscheduled;

		// NOTE: this function is responsible for transforming `newStart` and `newEnd`,
		// which were initialized to the OLD values first. `newEnd` may be null.

		// Set new start if we are coming from null dates to valid dates (dragged from unscheduled to calendar)
		if (!newStart && event.start) {
			newStart = event.start.clone();
		}

		// normlize newStart/newEnd to be consistent with newAllDay
		if (newAllDay) {
			if (newStart) {
				newStart.stripTime();
			}
			if (newEnd) {
				newEnd.stripTime();
			}
		} else {
			if (newStart && !newStart.hasTime()) {
				newStart = t.rezoneDate(newStart);
			}
			if (newEnd && !newEnd.hasTime()) {
				newEnd = t.rezoneDate(newEnd);
			}
		}

		// ensure we have an end date if necessary
		if (
			newStart &&
			!newEnd &&
			(options.forceEventDuration || +durationDelta)
		) {
			newEnd = t.getDefaultEventEnd(newAllDay, newStart);
		}

		// translate the dates
		if (newAllDay) {
			//We convert to UTC using unzone so we can do math without DST issues. Then rezone to be in current TZ
			if (newStart) {
				newStart.add(dateDelta);
			}
			if (newEnd) {
				newEnd.add(dateDelta).add(durationDelta);
			}
		} else {
			//We convert to UTC using unzone so we can do math without DST issues. Then rezone to be in current TZ
			if (newStart) {
				newStart = t.rezoneDate(t.unzoneDate(newStart).add(dateDelta));
			}
			if (newEnd) {
				newEnd = t.rezoneDate(
					t.unzoneDate(newEnd).add(dateDelta).add(durationDelta)
				);
			}
		}

		// if the dates have changed, and we know it is impossible to recompute the
		// timezone offsets, strip the zone.
		if (isAmbigTimezone) {
			if (+dateDelta || +durationDelta) {
				newStart.stripZone();
				if (newEnd) {
					newEnd.stripZone();
				}
			}
		}
		if (breakoutItem !== undefined && breakoutItem !== null) {
			event[breakoutField] = breakoutItem;
		}
		//Ensure that end is never before start
		if (newStart && newEnd.isBefore(newStart)) {
			newEnd = newStart.clone();
		}

		event.allDay = newAllDay;
		event.start = newStart;
		event.end = newEnd;

		backupEventDates(event);
		if (Array.isArray(undoFunctions)) {
			undoFunctions.push(function () {
				event.allDay = oldAllDay;
				event.start = oldStart;
				event.end = oldEnd;
				event[breakoutField] = oldBreakoutItem;
				event.isDefaultResource = oldIsDefaultResource;
				event.unscheduled = oldUnscheduled;
				backupEventDates(event);
			});
		}
	}

	// Modifies an array of events in the following ways (operations are in order):
	// - clear the event's `end`
	// - convert the event to allDay
	// - add `dateDelta` to the start and end
	// - add `durationDelta` to the event's duration
	//
	// Returns a function that can be called to undo all the operations.
	//
	function mutateEvents(
		events,
		clearEnd,
		forceAllDay,
		dateDelta,
		durationDelta,
		breakoutData
	) {
		var undoFunctions = [];

		$.each(events, function (i, event) {
			getMutateChanges(
				event,
				clearEnd,
				forceAllDay,
				dateDelta,
				durationDelta,
				breakoutData,
				undoFunctions
			);
		});

		return function () {
			for (var i = 0; i < undoFunctions.length; i++) {
				undoFunctions[i]();
			}
		};
	}

	/* Utils
	------------------------------------------------------------------------------*/

	function normalizeSource(source) {
		if (source.className) {
			// TODO: repeat code, same code for event classNames
			if (typeof source.className == 'string') {
				source.className = source.className.split(/\s+/);
			}
		} else {
			source.className = [];
		}
		var normalizers = fc.sourceNormalizers;
		for (var i = 0; i < normalizers.length; i++) {
			normalizers[i].call(t, source);
		}
	}

	function isSourcesEqual(source1, source2) {
		return (
			source1 &&
			source2 &&
			getSourcePrimitive(source1) == getSourcePrimitive(source2)
		);
	}

	function getSourcePrimitive(source) {
		return (
			(typeof source == 'object' ? source.events || source.url : '') ||
			source
		);
	}
}

// updates the "backup" properties, which are preserved in order to compute diffs later on.
function backupEventDates(event) {
	event._allDay = event.allDay;
	event._start = event.start ? event.start.clone() : null;
	event._end = event.end ? event.end.clone() : null;
	event._unscheduled = event.unscheduled;
	event._geocode = event.geocode
		? {lat: event.geocode.lat, lng: event.geocode.lng}
		: null;
}
